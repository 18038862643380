import React from 'react';
import {Accordion} from 'react-bootstrap';
import {SoundCloudPlayer} from "./SoundCloudPlayer";
import {YouTubePlayer} from "./YouTubePlayer";

// @ts-ignore
export const IBC2024 = () => (
    <div>
        <p/>
        <h2>Indigenous Bahá'í Ceremony 2024</h2>
        <Accordion defaultActiveKey="46">
            <Accordion.Item eventKey="46">
                <Accordion.Header>
                    <center>
                        <h4>Vibrant Vibrations - Reflections on the Culture of Sound</h4>
                        <h4>Jordan Bighorn</h4>
                        <h4>Sunday 22nd December 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_12.jpeg"}
                             alt={"Jordan Bighorn"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide Zoom link)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4><a href={"https://us02web.zoom.us/j/83736854476?pwd=Zv4rbJEFTUDb9gWh8IMjlEGWbuDpVn.1"}>
                            Click Sunday 22nd December 2024 2:00pm PST to join live Zoom session</a>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="45">
                <Accordion.Header>
                    <center>
                        <h4>Village of Huslia</h4>
                        <h4>Sonia Vent</h4>
                        <h4>Sunday 24th November 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_11.jpeg"}
                             alt={"Sonya Vent"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide Zoom link)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            video or audio coming soon
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="44">
                <Accordion.Header>
                    <center>
                        <h4>Stories - Remembering Ruby Gubatayao</h4>
                        <h4>Tsimshian</h4>
                        <h4>Sunday 1st September 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_08.png"}
                             alt={"Ruby Gubatayao"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video and audio links)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            video or audio not available yet
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="43">
                <Accordion.Header>
                    <center>
                        <h4>Jacqueline Left Hand Bull</h4>
                        <h4>member of the Sicangu Lakota of the Rosebud Sioux Tribe</h4>
                        <h4>Sunday 4th August 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_07.jpg"}
                             alt={"Jacqueline Left Hand Bull"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1302440395%3Fsecret_token%3Ds-rFSdM9WJMDc&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/i3zVrI6t0Ug" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="42">
                <Accordion.Header>
                    <center>
                        <h4>Mr. John Bathke - Dine' (Navajo)</h4>
                        <h4>Sunday 30th June 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_06.jpg"}
                             alt={"John Bathke"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
"                           Coming Soon
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="41">
                <Accordion.Header>
                    <center>
                        <h4>Dr. Scott Tyler - Makah</h4>
                        <h4>Sunday 26th May 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_05.jpg"}
                             alt={"Scott Tyler"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1857581091&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/_4XkJpV983o?si=D2Ps2PHdPvOE3kx"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="40">
                <Accordion.Header>
                    <center>
                        <h4>Naiomi Michalsen - Ketchikan</h4>
                        <h4>Sunday 28th February 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_02.jpg"}
                             alt={"Naiomi Michalsen"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1758026364&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/dp5RwHNjKrs"/>
                            </h4>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="39">
                <Accordion.Header>
                    <center>
                        <h4>John Gubatayao and Marey Theresa Gubatayao - Tsimshian</h4>
                        <h4>Sunday 28th January 2024 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2024_01.jpg"}
                             alt={"John and Marey Gubatayao 2023 Reflections"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1758027930&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/anWZZeFRnvc"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
)

